<template>
    <div class="content">
        <div class="wrap-summary">
            <div class="summary-data">
                <div>Total Buy</div>
                <div>
                    <span>{{ formatPrice(summaryData.total_gram_buy) }}</span>
                    <span>gr</span>
                </div>
            </div>
            <div class="summary-data">
                <div>Total Sell</div>
                <div>
                    <span>{{ formatPrice(summaryData.total_gram_sell) }}</span>
                    <span>gr</span>
                </div>
            </div>
            <div class="summary-data">
                <div>Netting</div>
                <div>
                    <span>{{ formatPrice(summaryData.netting) }}</span>
                    <span>gr</span>
                </div>
            </div>
            <div class="summary-data">
                <div>Remaining Stock</div>
                <div>
                    <span>{{ formatPrice(summaryData.physical_stock) }}</span>
                    <span>gr</span>
                </div>
            </div>
            <div class="summary-data">
                <div>Total Stock</div>
                <div>
                    <span>{{ formatPrice(summaryData.total_stock) }}</span>
                    <span>gr</span>
                </div>
            </div>
        </div>
        <table>
            <thead>
                <tr id="top">
                    <th scope="col" style="width: 70px">No</th>
                    <th scope="col">Name</th>
                    <th scope="col">HIN</th>
                    <th scope="col">Total Stock</th>
                    <th scope="col">Total Pieces</th>
                    <th scope="col">Total Buy</th>
                    <th scope="col">Total Sell</th>
                    <th scope="col">Netting</th>
                    <th scope="col">Remaining Stock</th>
                    <th scope="col">Status</th>
                    <th scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(inventory, i) in dataTransaction" :key="i">
                    <td data-label="No">{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                    <td data-label="Name">{{ inventory.fullname }}</td>
                    <td data-label="HIN">{{ inventory.hin }}</td>
                    <td data-label="Total Stock">{{ formatPrice(inventory.total_stock) }}</td>
                    <td data-label="Total Pieces">{{ formatPrice(inventory.total_pieces) }}</td>
                    <td data-label="Total Buy">{{ formatPrice(inventory.total_buy) }}</td>
                    <td data-label="Total Sell">{{ formatPrice(inventory.total_sell) }}</td>
                    <td data-label="Netting">{{ formatPrice(inventory.netting) }}</td>
                    <td data-label="Remaining Stock">{{ formatPrice(inventory.remaining_stock) }}</td>
                    <td data-label="Status">
                        <span v-if="inventory.remaining_stock > 10000"> Available</span>
                        <span v-else-if="Number(inventory.remaining_stock) < 10000 && Number(inventory.remaining_stock) > 5000"> Consider</span>
                        <span v-else-if="Number(inventory.remaining_stock) < 5000"> Suspended</span>
                    </td>
                    <td data-label="Detail">
                        <router-link :to="'/on-exchange/inventory/' + inventory.hin">
                            <button type="button" class="btn btn-outline-info btn-sm">Detail</button>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <b-pagination
            v-model="currentPage"
            :total-rows="lengthData"
            :per-page="perPage"
            @change="handlePageChange"
            aria-controls="new-table"
            first-number
            last-number
            v-show="lengthData > 10"
        ></b-pagination>
    </div>
</template>

<script>
import axios from "axios";
// import ExportDeposit from "../components/ExportDeposit.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
    // components: { ExportDeposit },
    data() {
        return {
            dataTransaction: [],
            type: "text",
            typeDeposit: "onexchange",
            pageOfItems: [],
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            trade_id: [],
            offset: 0,
            data: [],
            form: {
                startDate: "",
                endDate: "",
                depositor: "",
            },
            toggleDownload: false,
            isHideDeposite: false,
            summaryData: null,
        };
    },
    mounted() {
        axios.get("/v2/clearing/admin/get/summary_inventory").then((res) => {
            this.summaryData = res.data.data.body;
        });
        this.getData(this.currentPage, this.typeDeposit);
    },
    methods: {
        formatPrice(value) {
            let val = Math.round((value + Number.EPSILON) * 100) / 100;
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        openMenu() {
            this.toggleDownload = !this.toggleDownload;
        },
        handlePageChange(value) {
            this.getData(value, this.typeDeposit);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        filterTypeDeposit(value) {
            this.typeDeposit = value;
            this.getData(this.currentPage, value);
        },
        getData(value, typeDeposit) {
            let offset = (value - 1) * this.perPage;
            let url;
            let role = sessionStorage.getItem("role");
            if (role == 2 || role == 3) {
                url = "v2/clearing/admin/abx/get/list/summary_inventory?startDate&endDate&limit&offset" + offset + "&type=" + typeDeposit;
            } else {
                url = "v2/clearing/admin/get/list/summary_inventory?startDate&endDate&limit&offset=" + offset + "&type=" + typeDeposit;
            }
            axios.get(url).then((res) => {
                // this.dataTransaction = res.data.data.body;
                let dataSummary = res.data.data.body;
                for (let i = 0; i < dataSummary.length; i++) {
                    axios.get("/v2/clearing/admin/get/total_gold?" + "hin=" + dataSummary[i].depositor).then((resGold) => {
                        let dataGold = resGold.data.data.body;
                        let dataObject = {
                            fullname: dataGold.fullname ? dataGold.fullname : "",
                            hin: dataGold.hin ? dataGold.hin : "",
                            total_stock: dataSummary[i].weight,
                            total_pieces: dataSummary[i].quantity,
                            total_buy: dataGold.gram_buy,
                            total_sell: dataGold.gram_sell,
                            netting: dataGold.netting,
                            remaining_stock: dataSummary[i].weight - dataGold.netting,
                        };

                        this.$set(this.dataTransaction, i, dataObject);

                        // this.dataTransaction.push(dataObject);
                    });
                }
                this.lengthData = res.data.data.count;
            });
        },
        downloadDeposit() {
            // let fileName = "deposit-confirmation.pdf";
            let dataId = document.getElementById("downloadMenu");
            let pdf = new jsPDF({
                orientation: "portrait",
                format: "a4",
            });
            html2canvas(dataId, { useCORS: true, scale: 1 }).then((canvas) => {
                var margin = 8;
                var imgWidth = 210 - 2 * margin;
                var imgHeight = (canvas.height * imgWidth) / canvas.width;
                var pageHeight = 295;
                var heightLeft = imgHeight;
                let img = canvas.toDataURL("image/png", 1);

                var position = 1;
                pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                pdf.save("deposit-confirmation");
            });
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
    },
};
</script>

<style scoped>
.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

select,
input {
    border-radius: 3px;
    border: 1px solid #a5a5a5a5;
    padding: 3px;
    width: 100%;
    margin-bottom: 0.5rem;
}

input:last-child {
    margin-bottom: unset;
}

.wrap-summary {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
    gap: 1rem;
}

.summary-data {
    /* box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    width: 22%;
    border: solid 1px #dddddd;
}

.summary-data div:last-child {
    float: right;
}

.summary-data div:last-child span:first-child {
    font-size: 1.75rem;
    margin-right: 0.5rem;
    font-weight: bold;
}

.summary-data:hover {
    /* box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
}

.wrap-download {
    position: relative;
}

.menu-download {
    position: absolute;
    margin-bottom: 1rem;
    background: #fff;
    width: 17rem;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 1rem;
    font-size: 13px;
    right: 0;
    top: 42px;
}

.nav-tab {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #636366;
    padding: 0.8rem 0;
}

.tab {
    padding: 0.8rem 1rem;
    cursor: pointer;
}

.tab:hover,
.activeTab {
    color: var(--scondary-color);
    border-bottom: 2px solid var(--scondary-color);
}
</style>
